import './Contact.css';
import { useRef } from 'react';
import { addDoc, collection } from "firebase/firestore";
import { firestore  } from '../../Utils/firebase';
import { uid } from 'uid';
import { ref, set } from 'firebase/database';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

function Contact() {

  let fNameRef = useRef();
  let fEmailname = useRef();
  let fNumberRef = useRef();
  let fSubjectRef = useRef();
  let fMessageRef = useRef();

  const [okay, setOkay] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
 
  function onSendMessage(event){
    event.preventDefault();
    
    let note = {
      fullname: fNameRef.current.value,
      email: fEmailname.current.value,
      whatsapp: fNumberRef.current.value,
      subject: fSubjectRef.current.value,
      message: fMessageRef.current.value
    }
    
    // Form validation
    let isValid = true;

    // Rule 1: Check if any field is empty
    if (
      note.fullname === '' ||
      note.email === '' ||
      note.subject === '' ||
      note.message === ''
    ) {
      isValid = false;
      // Apply red border to empty fields
      if (note.fullname === '') {
        fNameRef.current.classList.add('redborder');
      }
      if (note.email === '') {
        fEmailname.current.classList.add('redborder');
      }
      if (note.subject === '') {
        fSubjectRef.current.classList.add('redborder');
      }
      if (note.message === '') {
        fMessageRef.current.classList.add('redborder');
      }
    } else {
      // Remove red border from all fields
      fNameRef.current.classList.remove('redborder');
      fEmailname.current.classList.remove('redborder');
      fSubjectRef.current.classList.remove('redborder');
      fMessageRef.current.classList.remove('redborder');
    }

    // Rule 2: Check if email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(note.email)) {
      isValid = false;
      // Apply red border to invalid email field
      fEmailname.current.classList.add('redborder');
    }

    // Rule 3: Check if subject length is between 4 and 100
    // if (note.subject.length < 4 || note.subject.length > 100) {
    //   isValid = false;
    //   // Apply red border to invalid subject field
    //   fSubjectRef.current.classList.add('redborder');
    // }

    // Rule 4: Check if message length is between 4 words and 400
    const wordCount = note.message.trim().split(/\s+/).length;
    if (wordCount < 4 || wordCount > 400) {
      isValid = false;
      // Apply red border to invalid message field
      fMessageRef.current.classList.add('redborder');
      console.log('4');
    }

   
    if (isValid) {
      // All fields are valid
      // Call the API or perform further actions here
      setOkay(true);
      console.log('Form is valid');
    } else {
      // At least one field is invalid
      setOkay(false);
      console.log('Form is invalid');
    }

    if (okay) {
      // send
      const uuid = uid()
      set(ref(firestore, `/${uuid}`), {
        note: note,
        uuid: uuid
      });
      setFormSubmitted(true);
      // Clear all fields
      fNameRef.current.value = '';
      fEmailname.current.value = '';
      fNumberRef.current.value = '';
      fSubjectRef.current.value = '';
      fMessageRef.current.value = '';

    } else {
      console.log('not sent');
      setFormSubmitted(false);
    }
    



  }

  return (
    <>
        <section className="contact" id="contact">
          <h2 className="heading">Contact <span>Me!</span></h2>
          {formSubmitted && <div className="message">Thank you for reaching out! </div>}
          <form action="" onSubmit={onSendMessage}>
              <div className="input-box">
                  <input type="text" placeholder="Full Name" ref={fNameRef}/>
                  <input type="text" placeholder="Email Address" ref={fEmailname}/>
              </div>
              <div className="input-box">
                  <input type="text" placeholder="WhatsApp Number" ref={fNumberRef}/>
                  <input type="text" placeholder="Message Subject" ref={fSubjectRef}/>
              </div>
              <textarea name="" id="" cols="30" rows="10" placeholder="Your Message" ref={fMessageRef}/>
              <div className="small-text">Words between 4 and 400</div>
              <input type="submit" value="Send Message" className="btn" />
          </form>
        </section>
    </>
  );
}

export default Contact;


// npm install firebase
// npm install uid