import './Footer.css'

function Footer() {
  return (
    <>
        <footer className="footer">
        <div className="footer-text">
            <p>Copyright &copy; 2023. Tajudeen&nbsp;Alli. All&nbsp;Rights&nbsp;Reserved. <br /><a href="https://www.newbrandhosting.com" target="_blank" rel="noreferrer">NewBrand Hosting & NewBrand Technology</a></p>
        </div>
        <div className="footer-iconTop">
            <a href="#home"><i className='bx bxs-to-top'></i></a>
        </div>
    </footer>
    </>
  );
}

export default Footer;
