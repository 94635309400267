import './Services.css'

function Services() {
  return (
    <>
            <section className="services" id="services">

        <h2 className="heading">My <span>Services</span></h2>
        <div className="services-container">
            <div className="services-box">
                <i className='bx bx-code-block'></i>
                <h3>Website Development</h3>
                <p>As a website developer, I specialize in creating custom websites. </p>
                <p>I combine my technical expertise with a keen eye for design to craft visually appealing and responsive websites that leave a lasting impression.</p>                
            </div>

            <div className="services-box">
                <i className='bx bx-palette'></i>
                <h3>Front-end Development</h3>
                <p>As a front-end developer, I bring designs to life by building interactive and engaging user interfaces. </p>
                <p>Through my mastery of HTML, CSS, and JavaScript, I create responsive websites that provide exceptional user experiences across various devices and platforms.</p>                
            </div>

            <div className="services-box">
                <i className='bx bx-server'></i>
                <h3>Back-end Development</h3>
                <p>As a back-end developer, I excel in handling the behind-the-scenes aspects of web development. </p><p>I work with server-side programming languages, databases, and frameworks to build robust and scalable web applications. My focus is on optimizing performance, security, and data management.</p>    
            </div>

            <div className="services-box">
                <i className='bx bx-cog'></i>
                <h3>Website Maintenance and Updates</h3>
                <p>As a web developer specializing in maintenance and updates, I ensure that websites are kept up-to-date, secure, and running smoothly. </p><p>With a meticulous approach, I perform routine checks, address bugs, implement updates, and make enhancements to provide a seamless browsing experience for users</p>
            </div>
        </div>
    </section>

    </>
  );
}

export default Services;
