import ReactDOM from 'react-dom';
import React, {useState} from 'react';


function PortalComponent(props){

   const {date, topic, description} = props.selectedContent;
   console.log(date);
  

    return ReactDOM.createPortal(<>
	    <div className={props.modal ? "modalGuyOverlay" : "modalGuyOverlay hide"} onClick={props.hideModal}></div>
        <div className={props.modal ? "modalGuy" : "modalGuy hide"}>
        <i className="bx bx-x" onClick={props.hideModal}></i>
        <span className="note">
            <span className="topic"><h2>{topic}</h2></span>
            <span className="date">{date}</span>
            {/* <span className="description">{description}</span> */}
            <span className="description" dangerouslySetInnerHTML={{ __html: description }}></span>
        </span>
        </div>
	    </>, document.getElementById('root-portal'));

}
export default PortalComponent;
