import './Hero.css';
import React, { useState, useEffect } from 'react';
import Typedscript from '../../Utils/Typedscript';

function Hero(){

    const [skills, setSkills] = useState('');

    function showSkills(event){
        let id = event.target.id;
        setSkills(id);
    }
    function dontShow(event){
        setSkills('');
    }

    return <>
        <section className="home" id="home">
        <div id="particles-js"></div>
        <div className="home-content">
            <h3>Hi!</h3>
            <h1>I'm Tajudeen Alli</h1>
            <h3><span className="multiple-text">A Web Developer</span></h3>
            {/* <h3>I'm <span className="multiple-text"><Typedscript /></span></h3> */}
            
            <p>Passionate web developer with expertise in front-end and back-end technologies. Experienced in creating responsive and dynamic web applications.
            </p>
            <a href="#contact" className="btn">Contact me</a>
        </div>

        <div className="home-img">

            <div className={skills === 'frontend' ? 'frontend skillsActive' : 'frontend noSkillsActive'}>
                <span>ReactJS</span>
                <span>Angular</span>
                <span>JavaScript</span>
                <span>HTML, CSS</span>
                <span>Bootstrap</span>
                <span>Others...</span>
            </div>
            <div className={skills === 'backend' ? 'backend skillsActive' : 'backend noSkillsActive'}>
                <span>Python</span>
                <span>Flask</span>
                <span>NodeJs</span>
                <span>Django</span>
                <span>Others...</span>
            </div>
            <div className={skills === 'content' ? 'content skillsActive' : 'content noSkillsActive'}>
                <span>Wordpress</span>
                <span>Joomla</span>
                <span>Others...</span>
            </div>
            <div className={skills === 'graphics' ? 'graphics skillsActive' : 'graphics noSkillsActive'}>
                <span>PhotoShop</span>
                <span>Illustrator</span>
                <span>Figma</span>
                <span>Others...</span>
            </div>
            <div className={skills === 'projectmanage' ? 'projectmanage skillsActive' : 'projectmanage noSkillsActive'}>
                <span>Projects</span>
                <span>Consultancy</span>
                <span>Others...</span>
            </div>

            <div className="skills" id="frontend" onClick={showSkills} onMouseOver={showSkills} onMouseLeave={dontShow}></div>
            <div className="skills" id="backend" onClick={showSkills} onMouseOver={showSkills} onMouseLeave={dontShow}></div>
            <div className="skills" id="content" onClick={showSkills} onMouseOver={showSkills} onMouseLeave={dontShow}></div>
            <div className="skills" id="graphics" onClick={showSkills} onMouseOver={showSkills} onMouseLeave={dontShow}></div>
            <div className="skills" id="projectmanage" onClick={showSkills} onMouseOver={showSkills} onMouseLeave={dontShow}></div>

        </div>

    </section>
    </>
}

export default Hero;
