import './About.css';

function About(){

return (<>
    <section className="about" id="about">
        <div className="about-img">
        </div>
        <div className="about-content">
            <h2 className="heading">About <span>Me</span></h2>
            <h3>Web Developer</h3>
            <p>Driven and innovative web developer with a flair for crafting seamless user experiences. Proficient in front-end and back-end technologies, leveraging HTML, CSS, Vanilla JavaScript, JQuery, and frameworks like React and Node.js. Strong problem-solving skills, collaborating with cross-functional teams to deliver high-quality, scalable web solutions. </p>
            <p>Constantly exploring emerging trends and technologies to stay ahead of the curve. Committed to creating visually stunning and performant websites that leave a lasting impact. Passionate about transforming ideas into reality and building digital experiences that captivate and inspire.</p>
            <a href="#contact" className="btn">Contact me</a>
        </div>
    </section>
    </>
    )
}

export default About;
