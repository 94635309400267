import react from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './Components/Header/Header';
import Hero from './Components/Hero/Hero';
import About from './Components/About/About';
import Services from './Components/Services/Services';
import Portfolio from './Components/Portfolio/Portfolio';
import Blog from './Components/Blog/Blog';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <>
        <Header></Header>
        <Hero></Hero>
        <About></About>
        <Services></Services>
        <Portfolio></Portfolio>
        <Blog></Blog>
        <Contact></Contact>
        <Footer></Footer>
    </>
  );
}

export default App;
