import './Blog.css';
import React, { useEffect, useState, useRef  } from 'react';
import PortalComponent from '../../Components/Portal/Portal';
import jsonData from '../../Inc/js/json.js';

function Blog() {

    const [jsonData, setJsonData] = useState([]);
    const [content, setContent] = useState('all');
    const [modal, setModal] = React.useState(false);
     const [selectedContent, setSelectedContent] = React.useState({
        date: '',
        topic: '',
        description: ''
    });
    const areas = ['all', 'js', 'react', 'angular', 'pythonflask', 'mix'];

    // fetching the json data
    useEffect(() => {
        // Fetch the JSON data
        const data = window.jsonData;
        setJsonData(data);
      }, []);

    // displaying the blog content
    function showContent(event){
        let id = event.target.id;
        id = id.substring(5);
        setContent(id);
    }
    // when the line under the blog menu is clicked
    function showContentAlso(event){
        let idSmall = event.target.id;
        idSmall = idSmall.substring(6);
        setContent(idSmall);
    }

    // increasing the height of the article div
    const articleShellRef = useRef(null);
    const articleParentRef = useRef(null);
  
    const updateArticleHeight = () => {
        if (articleParentRef.current && articleShellRef.current) {
            articleParentRef.current.style.height = articleShellRef.current.offsetHeight + 'px';
            // console.log('parent' + articleParentRef.current.offsetHeight);
            console.log(articleShellRef.current.offsetHeight);
            console.log(articleShellRef.current);
        }
    }
    useEffect(()=>{
		updateArticleHeight();
        window.addEventListener('resize', updateArticleHeight);
        return () => {
            window.removeEventListener('resize', updateArticleHeight);
          };
        
	}, [articleShellRef.current, articleParentRef.current])

  
    // Modal stuff
    function showModal(date, topic, description){
        setSelectedContent({
            date:date,
            topic:topic,
            description:description
        });
        setModal(true);
    }
    function hideModal(){
        setModal(false);
    }

    // Send the selected article to portal


    return (
    <>
        <section className="blog" id="blog">
        <h2 className="heading">Code <span>Blog</span></h2>
        <div className="blog-container">
            <div className="sidenav">
                <ul>
                    <li id="link-all" className={content === "all"? "blog-active": ""} onClick={showContent}>HTML/CSS <span className="small" id='small-all' onClick={showContentAlso}></span></li>
                    <li id="link-js" className={content === "js"? "blog-active": ""} onClick={showContent}>JavaScript <span className="small" id='small-js' onClick={showContentAlso}></span></li>
                    <li id="link-react" className={content === "react"? "blog-active": ""} onClick={showContent}>ReactJS <span className="small" id='small-react' onClick={showContentAlso}></span></li>
                    <li id="link-angular" className={content === "angular"? "blog-active": ""} onClick={showContent}>Angular <span className="small" id='small-angular' onClick={showContentAlso}></span></li>
                    <li id="link-pythonflask" className={content === "pythonflask"? "blog-active": ""} onClick={showContent}>Python/Flask <span className="small" id='small-pythonflask' onClick={showContentAlso}></span></li>
                    <li id="link-mix" className={content === "mix"? "blog-active": ""} onClick={showContent}>Mix Topics <span className="small" id='small-mix' onClick={showContentAlso}></span></li>
                </ul>
            </div>
            <div className="rightnav" ref={articleParentRef}>

                {areas.map((item, index) => (
                    <div key={index}>
                        <div className={content === item ? "blog-active article" : "article"} id={item} >
                        {jsonData.map((itemChild, indexChild) => (
                            itemChild.dataInfo === item ? ( 
                            <div key={indexChild} onClick={() => showModal(itemChild.date, itemChild.topic, itemChild.description)}>
                                <span className="date">{itemChild.date}</span>
                                <span className="topic"><h2>{itemChild.topic}</h2></span>
                                {/* <span className="description" data-info="mix" dangerouslySetInnerHTML={{ __html: item.description }}></span> */}
                                <span className="description" data-info={itemChild.dataInfo}>
                                {itemChild.description.split(' ').slice(0, 10).join(' ')}
                                {itemChild.description.split(' ').length > 10 && <button>...read more</button>}
                                </span>
                            </div> ) : null

                            ))}
                        </div>
                    </div>
                ))}

                <div id="blogSender"><a href="#blog">Back to Blog Menu<i className='bx bxs-to-top'></i></a></div>
            </div>


        </div>
    </section>

    <PortalComponent modal={modal} showModal={showModal} hideModal={hideModal} selectedContent={selectedContent}/>
    </>
  );
}

export default Blog;
