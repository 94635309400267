import './Portfolio.css'

import newbrandhosting from '../../Assets/newbrandhosting.png';
import mine from '../../Assets/mine.png';
import schoolmgtsystem from '../../Assets/schoolmgtsystem.jpg';
import sbecwebsite from '../../Assets/sbecwebsite.png';
import foodcateensbec from '../../Assets/foodcateensbec.jpg';
import charity from '../../Assets/charity.jpg';

function Portfolio() {
  return (
    <>
    <section className="portfolio" id="portfolio">
        <h2 className="heading">My <span>Projects</span></h2>
        <div className="portfolio-container">
            <div className="portfolio-box">
                <img src={sbecwebsite} alt="Portfolio" />
                <div className="portfolio-layer">
                    <h4>International School Website</h4>
                    <p>7 Schools in one website<br />Built with innovation </p>
                    <a href="http://demo1.newbrandhosting.com/" target="_blank" rel="noreferrer"><i className="bx bx-link-external"></i></a>
                </div>
            </div>
            <div className="portfolio-box">
                <img src={mine} alt="Portfolio" />
                <div className="portfolio-layer">
                    <h4>My Portfolio</h4>
                    <p>Creativity at work<br />Built with ReactJS/Python </p>
                    <a href="https://www.alli-invent.com"><i className="bx bx-link-external"></i></a>
                </div>
            </div>
            <div className="portfolio-box">
                <img src={schoolmgtsystem} alt="Portfolio" />
                <div className="portfolio-layer">
                    <h4>Complete School Management System</h4>
                    <p>Fullstack Project<br /> Hosting more than 2000 users </p>
                    <a href="https://www.sbeconline.com" target="_blank" rel="noreferrer"><i className="bx bx-link-external"></i></a>
                </div>
            </div>
            <div className="portfolio-box">
                <img src={foodcateensbec} alt="Portfolio" />
                <div className="portfolio-layer">
                    <h4>Online Food Canteen System</h4>
                    <p>Built for creativity<br />For students, parents and staff. </p>
                    <a href="https://sbecfoodcanteen.com" target="_blank" rel="noreferrer"><i className="bx bx-link-external"></i></a>
                </div>
            </div>
            <div className="portfolio-box">
                <img src={newbrandhosting} alt="Portfolio" />
                <div className="portfolio-layer">
                    <h4>Hosting Site</h4>
                    <p>Web Hosting Site<br />Powerful Hepsia Cpanel</p>
                    <a href="https://www.newbrandhosting.com" target="_blank" rel="noreferrer"><i className="bx bx-link-external"></i></a>
                </div>
            </div>
            <div className="portfolio-box">
                <img src={charity} alt="Portfolio" />
                <div className="portfolio-layer">
                    <h4>Charity Foundation</h4>
                    <p>A Charity Foundation<br />Built with Purpose </p>
                    <a href="https://www.fp-wn.org" target="_blank" rel="noreferrer"><i className="bx bx-link-external"></i></a>
                </div>
            </div>
        </div>
    </section>

    </>
  );
}

export default Portfolio;
