import './Header.css'
import React, { useState } from 'react';

function Header() {

    const [inMenuOpen, setIsMenuOpen] = useState(false);
    function menuClicked(){
        setIsMenuOpen(!inMenuOpen);
    }

    return <>
        <header className="header">
        <div className="newbrand">Tajudeen Alli</div>

        <a href="#" className="logo"></a>

        <i id="menu-icon" onClick={menuClicked} className={inMenuOpen ? 'bx bx-x' : 'bx bx-menu'}></i>

        <nav className={inMenuOpen ? 'navbar active' : 'navbar'}>
            <a href="#home" className="active" onClick={menuClicked}>Home</a>
            <a href="#about" onClick={menuClicked}>About</a>
            <a href="#services" onClick={menuClicked}>Services</a>
            <a href="#portfolio"onClick={menuClicked}>Portfolio</a>
            <a href="#blog" onClick={menuClicked}>Code Blog</a>
            <a href="#contact" onClick={menuClicked}>Contact</a>
        </nav>
    </header>
    </>
}

export default Header;
